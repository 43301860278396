import {
  Photo,
  Container,
  PhotoNoBg,
  Wrap,
  NoBgWrap,
} from './ProfilePhoto.style';

import profile from '../../assets/profile.png';
import profileNoBg from '../../assets/profile-no-bg.png';
import { useThemeContext } from '../../context/ThemeContext';

const ProfilePhoto = () => {
  const { darkTheme } = useThemeContext();

  return (
    <Container>
      <Wrap>
        <Photo src={profile} alt="profile photo" />
      </Wrap>
      <NoBgWrap darkTheme={darkTheme}>
        <PhotoNoBg src={profileNoBg} alt="profile photo" />
      </NoBgWrap>
    </Container>
  );
};

export default ProfilePhoto;
