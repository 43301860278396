import React from 'react';

import { Hero, Projects } from '../../components';

const Home = () => {
  return (
    <>
      <Hero />
      <Projects />
    </>
  );
};

export default Home;
