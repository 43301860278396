import * as Img from './images';

export const projectsList = [
  {
    type: 'project',
    id: 'budgeteer',
    name: 'Budgeteer',
    tags: ['Finance', 'Budgeting', 'Calculator'],
    summary: 'A tool to help better budget your finances in advance.',
    thumbnail: Img.budgeteer_thumbnail,
    description: [
      'Budgeteer helps you manage your finances on the long run via budgets. You can add your income and expenses you intend to make. It then calculates how long you need to save to reach your goals, depending on the calculaton method you set. It also charts your budgets and expenses.',
      'I handled both the interface design and the fine-tuning during development independently. Being the sole developer allowed me to work efficiently and swiftly.',
    ],
    tooling: [
      'React',
      'Vite',
      'Vitest',
      'TailwindCSS',
      'Zustand',
      'React-Router',
      'Recharts',
      'React Table Library',
      'React Testing Library',
    ],
    functionalities: [
      'lets you add income and expenses',
      'lets you create budgets with flexible allocation per item',
      'tells you how long you need to save to reach your goals',
      'charts your budgets and expenses',
    ],
    urls: {
      live: 'https://budgeteeer.netlify.app/',
      // github: 'https://github.com/cyanhead/budgeteer',
    },
    images: {
      banner: [Img.budgeteer_banner1, Img.budgeteer_banner2], // 2
      concept: Img.budgeteer_concept, // 1
      spotlight: Img.budgeteer_spotlight, // 1
      gallery: [
        Img.budgeteer_gallery1,
        Img.budgeteer_gallery2,
        Img.budgeteer_gallery3,
      ], // 3
    },
    content: {
      purpose: [
        'I built this in order to better manage my finances. The idea came on a call with a friend. We were talking about how it would be nice to calculate the costs of moving into a new apartment and how much we would need to save, and for how long.',
        'I immediately drafted this design and shared it with my friend.',
      ],
      spotlight: [
        'The most interesting part of this project was implementing the tables and charts. Initially, I tried building them from the ground up, but that turned out to be quite a task. In the second go-around, I went for efficiency and leveraged the React Table Library and Recharts packages. These tools made data presentation a breeze, keeping things clear and concise.',
        // 'Using the table library, I was able to tabulate the data neatly and showcase the results of the calculations in a clear and concise manner.',
        'The most enjoyable tasks were adding those nifty color-coded tags on the budget page to showcase calculation methods of items, and linking up Zustand with local storage to make sure user-entered data stays put.',
      ],
      challenges: [
        'One of the challenges I faced was implementing the sorting of the tables. I kept getting unexpected results. I had to redesign the sorting logic and refactor the code to get it right. I also had to read up on the documentation of the library I was using to understand how to implement the sorting feature.',
        'Another challenge was figuring out the folder structure, particularly due to the use of TypeScript in this project. I wanted to keep the project as organized as possible, so I had to read up on best practices and had to refactor the project a couple of times to get it right.',
        // 'Another challenge I faced was with the deletion of multiple selected items in a budget table. Initially, when I deleted multiple items, their ids were not removed from the selected items array. I read the documentation and found a way to fix the issue using a provided function method. It amazed me how easy it was to fix an issue that seemed so complex.',
      ],
      status: [
        'The app is currently being used by me and my friends. I am working on adding more features, like the ability to search for items across all budgets, and an option to print the results. I am also working on improving the UI and UX of the app.',
      ],
      takeaways: [
        'Working on this project has given me a much better understanding on the nuances of state management. Prior to this project I would use React Context for bigger state management to avoid props drilling, and would inevitably fall into context hell. Now, I’m exposed to Zustand, and it’s such a great and simple tool to use.  It’s what powers the snappy state updates in the web app.',
        // 'I learnt how to use React Table Library and Recharts. I also got better at using unit testing.',
        'This was my first time using Vite and I must say, it’s a game changer. I’ve used Create React App and Next.js before, but Vite is so much faster and the development experience is so much better. I’m definitely going to use it for my future projects.',
      ],
    },
  },
  // {
  //   name: 'Grocerly',
  //   tags: ['E-commerce', 'React', 'Firebase'],
  //   summary: 'A one stop shop for all your grocery needs.',
  //   thumbnail: grocerly,
  //   description: [
  //     'Grocerly is an e-commerce platform built with React and Firebase. It is a one stop shop for all your grocery needs. You can buy items, search for items and filter items by category. You can also sign up and sign in to the app.',
  //     'The app is built with React and uses Firebase for authentication and database. Algolia is used for search and filtering.',
  //   ],
  //   tooling: ['React', 'Styled-Components', 'Firebase', 'Algolia'],
  //   functionalities: [
  //     'gives you user authentication',
  //     'allows you buy items',
  //     'lets you find items quickly by search and filter',
  //   ],
  //   urls: {
  //     live: 'https://grocerly-store.netlify.app',
  //     github: 'https://github.com/cyanhead/grocerly',
  //   },
  //   images: {
  //     banner: [''], // 2
  //     concept: '', // 1
  //     spotlight: '', // 1
  //     gallery: [], // 3
  //   },
  //   content: {
  //     purpose: [
  //       'I built this app to learn how to use Firebase and Algolia. I also wanted to learn how to build an e-commerce platform. I also wanted to learn how to build an e-commerce platform.',
  //       'Another reason I built this app was to learn how to build an e-commerce platform. I also wanted to learn how to build an e-commerce platform.',
  //     ],
  //     spotlight: [
  //       'I learnt how to use Firebase and Algolia. I also learnt how to build an e-commerce platform.',
  //       'I learnt how to use Firebase and Algolia. I also learnt how to build an e-commerce platform.',
  //     ],
  //     challenges: [
  //       'I had to learn how to use Firebase and Algolia. I also had to learn how to build an e-commerce platform.',
  //       'I had to learn how to use Firebase and Algolia. I also had to learn how to build an e-commerce platform.',
  //     ],
  //     status: [
  //       'I am currently working on adding more features to the app. I am also working on improving the UI and UX of the app.',
  //       'I am currently working on adding more features to the app. I am also working on improving the UI and UX of the app.',
  //     ],
  //     takeaways: [
  //       'I learnt how to use Firebase and Algolia. I also learnt how to build an e-commerce platform.',
  //       'I learnt how to use Firebase and Algolia. I also learnt how to build an e-commerce platform.',
  //     ],
  //   },
  // },

  // {
  //   name: '',
  //   tags: [],
  //   summary: '',
  //   thumbnail: '',
  //   description: [],
  //   tooling: [],
  //   functionalities: [],
  //   urls: {
  //     live: '',
  //     github: '',
  //   },
  //   images: {
  //     banner: [], // 2
  //     concept: '', // 1
  //     spotlight: '', // 1
  //     gallery: [], // 3
  //   },
  //   content: {
  //     purpose: [],
  //     spotlight: [],
  //     challenges: [],
  //     status: [],
  //     takeaways: [],
  //   },
  // },
];
