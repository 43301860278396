import {
  CommonHeading,
  CommonSubheading,
  Container,
  Gallery,
  Projects,
  Tag,
  TagGroup,
  TextAndArrow,
} from '../../components';
import { useThemeContext } from '../../context/ThemeContext';
import { useParams } from 'react-router-dom';
import { workList } from '../../data';
import {
  Banner,
  Image,
  Group,
  ImageWrap,
  Paragraph,
  Section,
  Subtitle,
  Title,
  ToolingAndFunctionalities,
  Wrap,
} from './WorkDetails.style';

const WorkDetails = () => {
  const { darkTheme } = useThemeContext();
  const { id } = useParams();

  const workProject = workList.find(project => project.id === id);

  if (!workProject) {
    return <h1>Work project not found</h1>;
  }

  const {
    type,
    name,
    summary,
    tags,
    description,
    tooling,
    objectives,
    urls,
    images,
    content,
  } = workProject;

  return (
    <>
      <Container darkTheme={darkTheme}>
        <Wrap>
          {/* SECTION 1: TITLE & INTRO */}
          <Section column>
            <CommonHeading>{name}</CommonHeading>
            <Group
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
              }}
            >
              <Paragraph>{summary}</Paragraph>
              <TagGroup>
                {type === 'project' && (
                  <Tag style={{ backgroundColor: 'cyan' }}>Personal</Tag>
                )}
                {tags.map((tag, index) => (
                  <Tag key={index} darkTheme={darkTheme}>
                    {tag}
                  </Tag>
                ))}
              </TagGroup>
            </Group>
            <Group>
              <CommonSubheading style={{ marginBottom: 12 }}>
                Overview
              </CommonSubheading>
              <Group
                customStyles={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',

                  '@media screen and (max -width: 768px)': {
                    flexDirection: 'row',
                    gap: '72px',
                  },
                }}
              >
                {description.map((paragraph, index) => (
                  <Paragraph key={index}>{paragraph}</Paragraph>
                ))}
              </Group>
            </Group>

            <ToolingAndFunctionalities>
              <TitleAndList title="This was built to;" list={objectives} />
              <TitleAndList title="To achieve this, I used;" list={tooling} />
            </ToolingAndFunctionalities>
            <Banner src={images.banner} alt="image of project user interface" />
            <Group
              customStyles={{
                display: 'flex',
                flexDirection: 'column',
                gap: '18px',
              }}
            >
              {urls && (
                <>
                  <Subtitle>Visit the</Subtitle>
                  <Group
                    customStyles={{
                      display: 'flex',
                      gap: '32px',
                      flexWrap: 'wrap',

                      '@media screen and (max-width: 480px)': {
                        gap: '16px',
                      },
                    }}
                  >
                    <TextAndArrow small externalUrl={urls.live}>
                      live website
                    </TextAndArrow>

                    {urls.github && (
                      <TextAndArrow small externalUrl={urls.github}>
                        github page
                      </TextAndArrow>
                    )}
                  </Group>
                </>
              )}
            </Group>
          </Section>

          {/* SECTION 2: MY ROLE */}
          <Section>
            <Group
              customStyles={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
              }}
            >
              <Title>My Role</Title>
              <Group
                customStyles={{
                  flex: '1',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                {content.role.map((paragraph, index) => (
                  <Paragraph key={index}>{paragraph}</Paragraph>
                ))}
              </Group>
            </Group>
            <ImageWrap>
              <Image src={images.concept} alt="image of concept design" />
            </ImageWrap>
          </Section>

          {/* SECTION 3: CHALLENGES & COGNITIVE APPROACH */}
          <Section
            column={!images.spotlight}
            flip={images.spotlight}
            middle={!images.spotlight}
          >
            <Group
              customStyles={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
              }}
            >
              <Title>Challenges & Cognitive Approach</Title>
              <Group
                customStyles={{
                  flex: '1',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                {content.challenges.map((paragraph, index) => (
                  <Paragraph key={index}>{paragraph}</Paragraph>
                ))}
              </Group>
            </Group>
            {images.spotlight && (
              <ImageWrap>
                <Image src={images.spotlight} alt="image of notable features" />
              </ImageWrap>
            )}
          </Section>

          {/* SECTION 4: OUTCOME */}
          <Section column middle>
            <Group
              customStyles={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
              }}
            >
              <Title>Outcome</Title>
              <Group
                customStyles={{
                  flex: '1',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                {content.outcome.map((paragraph, index) => (
                  <Paragraph key={index}>{paragraph}</Paragraph>
                ))}
              </Group>
            </Group>
            <Gallery images={images.gallery} />
          </Section>
        </Wrap>
      </Container>
      <Projects />
    </>
  );
};

const TitleAndList = ({ title, list }) => (
  <Group
    customStyles={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    }}
  >
    <Subtitle>{title}</Subtitle>
    <ul>
      {list.map((item, index) => (
        <li
          key={index}
          style={{
            listStylePosition: 'inside',
          }}
        >
          {item}
        </li>
      ))}
    </ul>
  </Group>
);

export default WorkDetails;
