import {
  RotationAnimation,
  WiggleAnimation,
  Wrapper,
} from './ThemeSwitch.style';
import { BsSunFill, BsMoonStarsFill } from 'react-icons/bs';
import { useThemeContext } from '../../context/ThemeContext';

const ThemeSwitch = () => {
  const { darkTheme, setDarkTheme } = useThemeContext();

  return (
    <Wrapper
      onClick={() => {
        setDarkTheme(!darkTheme);
      }}
    >
      {darkTheme ? (
        <RotationAnimation>
          <BsSunFill size={24} />
        </RotationAnimation>
      ) : (
        <WiggleAnimation>
          <BsMoonStarsFill size={24} />
        </WiggleAnimation>
      )}
    </Wrapper>
  );
};

export default ThemeSwitch;
