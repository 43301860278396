import styled, { css } from 'styled-components';

import {
  FiArrowLeft,
  FiArrowRight,
  FiMaximize,
  FiMinimize,
} from 'react-icons/fi';

const midBp = props => props.theme.bp.md;
const smallBp = props => props.theme.bp.sm;

const IconStyles = css`
  background-color: rgba(255, 255, 255, 0.5);
  padding: 4px;
  border-radius: 6px;

  font-size: 64px;

  /* 768px */
  @media screen and (max-width: ${midBp}) {
    font-size: 56px;
  }

  /* 640px */
  @media screen and (max-width: ${smallBp}) {
    font-size: 44px;
  }
`;

export const LeftArrow = styled(FiArrowLeft)`
  ${IconStyles}
`;

export const RightArrow = styled(FiArrowRight)`
  ${IconStyles}
`;

export const Maximize = styled(FiMaximize)`
  ${IconStyles}
`;

export const Minimize = styled(FiMinimize)`
  ${IconStyles}
`;

export const AttributionLink = styled.a`
  text-decoration: none;
  color: inherit;

  /* font-size: 1.25rem; */
  transition: 200ms ease-in-out;
  border-bottom: 1px solid;

  &:hover {
    border-bottom: 1px solid transparent;
  }
`;
