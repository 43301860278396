import React from 'react';

import { Container, Group, LogoText, Wrapper } from './NavBar.style';
import { NavLink } from '../common';
import { ThemeSwitch } from '..';
import { useThemeContext } from '../../context/ThemeContext';

const NavBar = () => {
  const { darkTheme } = useThemeContext();

  return (
    <Container darkTheme={darkTheme}>
      <Wrapper>
        {/* destop nav links */}
        <Group desktop>
          <GitHub />
          <Resume />
        </Group>
        <Group desktop>
          <Logo />
        </Group>
        <Group mobile>
          <Logo />
          <ThemeSwitch />
        </Group>
        <Group>
          {/* destop nav links */}
          <Group desktop>
            <Portfolio />
            <About />
          </Group>

          {/* mobile nav links */}
          <NavLinks mobile github />
          <Group desktop>
            <ThemeSwitch />
          </Group>
        </Group>
      </Wrapper>
    </Container>
  );
};

const Logo = () => {
  return <LogoText href="/">Bunmi Oye</LogoText>;
};

const Portfolio = () => <NavLink href="/#portfolio">portfolio</NavLink>;
const About = () => <NavLink href="/about">about</NavLink>;
const Resume = () => <NavLink href="/about#resume">resume</NavLink>;
const GitHub = () => (
  <NavLink external href="https://github.com/cyanhead">
    github
  </NavLink>
);

const Home = () => <NavLink href="/">home</NavLink>;

export const NavLinks = props => {
  return (
    <Group {...props}>
      {props.home && <Home />}
      <Portfolio />
      <About />
      <Resume />
      {props.github && <GitHub />}
    </Group>
  );
};

export default NavBar;
