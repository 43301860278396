import styled, { css } from 'styled-components';

const primary = props => props.theme.color.primary;
const secondary = props => props.theme.color.secondary;
const whiteFg = props => props.theme.color.bg.white;
const blackFg = props => props.theme.color.bg.black;

const midBp = props => props.theme.bp.md;

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => (p.small ? '10px' : '20px')};
`;

export const Text = styled.p`
  ${p =>
    p.small
      ? css`
          font-size: 1rem;
          font-weight: 400;
        `
      : css`
          font-size: 1.25rem;
          font-weight: 500;
        `}

  /* 768px */
  @media screen and (max-width: ${midBp}) {
    ${p => !p.small && `font-size: 1rem;`}
  }
`;

export const ArrowWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ trigger }) => (!trigger ? secondary : primary)};
  color: ${({ trigger }) => (!trigger ? whiteFg : blackFg)};

  padding: 8px;
  border-radius: 50%;

  transition: 200ms ease-in-out;

  font-size: 1.5rem;
  ${p => (p.small ? `font-size: 1rem;` : `font-size: 1.5rem;`)}

  @media screen and (max-width: ${midBp}) {
    font-size: 1.25rem;
  }
`;
