import React from 'react';
import { useThemeContext } from '../../context/ThemeContext';
import { CommonHeading, Container, Wrap } from '../common';
import { Section, P, Group, ColorSpan, CTA } from './hero.style';
import { getCurrentAge } from '../../helpers/getCurrentAge';

const Hero = () => {
  const { darkTheme } = useThemeContext();

  const age = getCurrentAge();

  return (
    <Container id="hero" darkTheme={darkTheme}>
      <Wrap
        reverseCol
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        <Section>
          <Group>
            <P>Hi there, I'm</P>
            <CommonHeading>Bunmi Oye.</CommonHeading>
            <CommonHeading>
              Frontend <ColorSpan darkTheme={darkTheme}>Developer</ColorSpan>
            </CommonHeading>
          </Group>
          <P
            style={{
              maxWidth: '600px',
            }}
          >
            I'm a {age}-year-old Nigerian passionate about creating user-centric
            products.
          </P>
          <CTA to="/#contact" darktheme={darkTheme ? 'true' : 'false'}>
            Contact me
          </CTA>
        </Section>
      </Wrap>
    </Container>
  );
};

export default Hero;
