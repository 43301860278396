import { createContext, useContext, useState } from 'react';

const Context = createContext();

export const ThemeContext = ({ children }) => {
  const [darkTheme, setDarkTheme] = useState(true);

  return (
    <Context.Provider
      value={{
        darkTheme,
        setDarkTheme,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useThemeContext = () => useContext(Context);
