import {
  Asterisk,
  Group,
  Heading,
  Left,
  P,
  ParagraphGroup,
  Resume,
  ResumeButton,
  Right,
  Section,
  SectionHeading,
  Tooltip,
  TooltipTrigger,
  Wrap,
} from './About.style';
import { Container, ProfilePhoto } from '../../components';
import { useThemeContext } from '../../context/ThemeContext';
import { about } from '../../data';

const About = () => {
  const { darkTheme } = useThemeContext();

  return (
    <Container darkTheme={darkTheme}>
      <Wrap>
        <Heading>About me in a nutshell</Heading>
        <Group>
          <Left>
            <ProfilePhoto />
            <Resume id="resume">
              <P>Explore my work history</P>
              <ResumeButton
                to={about.resumeURL}
                target="_blank"
                rel="noreferrer noopener"
                darktheme={darkTheme ? 'true' : 'false'}
              >
                Resume
              </ResumeButton>
            </Resume>
          </Left>

          <Right>
            <Section>
              <SectionHeading>Hello</SectionHeading>
              <P>
                I'm{' '}
                <TooltipTrigger>
                  Bunmi Oye<Asterisk darkTheme={darkTheme}>*</Asterisk>
                  <Tooltip darkTheme={darkTheme}>
                    First name: <strong>Boo-mhee</strong>
                    <br />
                    Surname: <strong>Oh-yay</strong>
                  </Tooltip>
                </TooltipTrigger>
                , a budding web developer from Nigeria. I like to build cool
                stuff on and off the web.
              </P>
            </Section>

            {about.paragraphs.map((section, index) => (
              <Section key={index}>
                <SectionHeading>{section.sectionTitle}</SectionHeading>
                <ParagraphGroup>
                  {section.content.map((item, index) => (
                    <P key={index}>{item}</P>
                  ))}
                </ParagraphGroup>
              </Section>
            ))}
          </Right>
        </Group>
      </Wrap>
    </Container>
  );
};

export default About;
