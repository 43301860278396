import { createGlobalStyle } from 'styled-components';

// Supports weights 100-900
import '@fontsource/montserrat-alternates';
import '@fontsource/montserrat-alternates/600.css';

import '@fontsource/syncopate';
import '@fontsource/syncopate/700.css';

export const GlobalStyle = createGlobalStyle`

    /* // * CSS RESET */
    /*
    1. Use a more-intuitive box-sizing model.
    */
    *, *::before, *::after {
      box-sizing: border-box;
    }

    /*
    2. Remove default margin
    */
    * {
      margin: 0;
      padding: 0;
    }

    /*
    3. Allow percentage-based heights in the application
    */
    html, body {
      height: 100%;
    }

    /*
    Typographic tweaks!
    4. Add accessible line-height
    5. Improve text rendering
    */
    body {
      line-height: 1.5;
      -webkit-font-smoothing: antialiased;
      /* font-family: "Lato", sans-serif; */
    }

    /*
    6. Improve media defaults
    */
    img, picture, video, canvas, svg {
      display: block;
      max-width: 100%;
    }

    /*
    7. Remove built-in form typography styles
    */
    input, button, textarea, select {
      font: inherit;
    }

    /*
    8. Avoid text overflows
    */
    p, h1, h2, h3, h4, h5, h6 {
      overflow-wrap: break-word;
    }

    /*
    9. Create a root stacking context
    */
    #root, #__next {
      isolation: isolate;
    }

    // * GLOBAL STYLES
    body {
      font-family: 'Montserrat Alternates', sans-serif;
    }

    h1, h2, h3, h4, h5, h6{
      font-family: 'Syncopate', sans-serif;
      font-weight: 700;
    }

    img {  
      user-drag: none;  
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }

`;
