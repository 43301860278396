import React from 'react';

import { Grid } from './ProjectGrid.style';
import { projectsList, workList } from '../../data';
import ProjectCard from '../ProjectCard';

const ProjectGrid = () => {
  const combinedList = [...projectsList, ...workList];

  return (
    <Grid>
      {combinedList?.map(
        ({ type, id, name, summary, thumbnail, tags }, index) => {
          return (
            <ProjectCard
              key={`project-${index}`}
              type={type}
              thumbnail={thumbnail}
              pathId={id}
              title={name}
              summary={summary}
              tags={tags}
            />
          );
        }
      )}
    </Grid>
  );
};

export default ProjectGrid;
