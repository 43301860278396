import styled from 'styled-components';
import {
  CommonHeading,
  CommonParagraph,
  Wrap as CommonWrap,
} from '../../components';
import { CTA } from '../../components/Hero/hero.style';

const largeBp = props => props.theme.bp.lg;
const midBp = props => props.theme.bp.md;
const smallBp = props => props.theme.bp.sm;
const xsBp = props => props.theme.bp.xs;

const primary = props => props.theme.color.primary;
const secondary = props => props.theme.color.secondary;

export const Wrap = styled(CommonWrap)`
  display: flex;
  flex-direction: column;

  margin-bottom: 6.25rem;
  gap: 3.5rem;

  /* 768px */
  @media screen and (max-width: ${midBp}) {
    gap: 2.5rem;
    margin-bottom: 4rem;
  }

  /* 640px */
  @media screen and (max-width: ${smallBp}) {
    gap: 2rem;
    margin-bottom: 0;
  }
`;

export const Heading = styled(CommonHeading)`
  text-align: center;

  /* 1024px */
  @media screen and (max-width: ${largeBp}) {
    text-align: left;
  }
`;

export const Group = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 48px;

  margin: 0 auto;

  /* 1024px */
  @media screen and (max-width: ${largeBp}) {
    flex-direction: column;
    margin: 0;

    gap: 64px;
  }

  /* 640px */
  @media screen and (max-width: ${xsBp}) {
    align-items: stretch;
  }
`;

export const Left = styled.div`
  position: sticky;
  top: 1.5rem;

  display: flex;
  flex-direction: column;

  text-align: justify;

  /* 1024 */
  @media screen and (max-width: ${largeBp}) {
    position: static;
  }
`;

export const P = styled(CommonParagraph)`
  text-align: justify;

  /* 1024 */
  @media screen and (max-width: ${largeBp}) {
    text-align: left;
  }
`;

export const Resume = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  width: 100%;
  padding-top: 32px;
`;

export const ResumeButton = styled(CTA)`
  width: 100%;
  text-align: center;

  padding: 18px 36px;

  /* 768px */
  @media screen and (max-width: ${midBp}) {
    padding: 12px;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  max-width: 700px;

  /* 768px */
  @media screen and (max-width: ${midBp}) {
    gap: 24px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SectionHeading = styled.p`
  font-size: 1.25rem;
  font-weight: 600;

  /* 768px */
  @media screen and (max-width: ${midBp}) {
    font-size: 1.125rem;
  }

  /* 640px */
  @media screen and (max-width: ${smallBp}) {
    font-size: 1rem;
  }
`;

export const ParagraphGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  /* 768px */
  @media screen and (max-width: ${midBp}) {
    gap: 16px;
  }
`;

export const TooltipTrigger = styled.span`
  position: relative;

  font-weight: 600;

  cursor: default;
  user-select: none;

  &:hover span {
    opacity: 1;
  }
`;

export const Tooltip = styled.span`
  position: absolute;
  top: -56px;
  left: 50%;
  z-index: 2;

  pointer-events: none;

  background-color: ${({ darkTheme }) => (darkTheme ? '#192733' : '#c5c5c5')};
  color: ${({ darkTheme }) => (darkTheme ? 'white' : 'black')};

  width: max-content;
  margin-left: -54px;
  padding: 4px 8px;
  border-radius: 10px;

  font-size: 14px;
  font-weight: 400;

  opacity: 0;
  transition: opacity 0.2s;
  transition-delay: 300ms;

  &::before {
    top: 90%;
    left: 45%;

    content: '';
    position: absolute;
    transform: rotate(45deg);
    background-color: ${({ darkTheme }) => (darkTheme ? '#192733' : '#c5c5c5')};
    padding: 5px;
    z-index: 1;
  }
`;

export const Asterisk = styled.span`
  color: ${({ darkTheme }) => (darkTheme ? primary : secondary)};
  font-size: 1.25rem;
  font-weight: 600;
`;
