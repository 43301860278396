import { useState } from 'react';
import { CopyToClipboard as CopyToClipboardPackage } from 'react-copy-to-clipboard';

import { Button, P } from './CopyToClipboard.style';
import { FiCheck } from 'react-icons/fi';

const CopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  const text = 'bunmi.oye@outlook.com';

  if (copied)
    return (
      <P>
        Copied! <FiCheck />
      </P>
    );

  return (
    <CopyToClipboardPackage
      text={text}
      onCopy={(text, result) => {
        setCopied(result);

        setTimeout(() => {
          setCopied(false);
        }, 3000);
      }}
    >
      <Button>Copy to clipboard</Button>
    </CopyToClipboardPackage>
  );
};

export default CopyToClipboard;
