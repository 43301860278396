// import { useEffect, useState } from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import './gallery.css';
import {
  AttributionLink,
  LeftArrow,
  Maximize,
  Minimize,
  RightArrow,
} from './gallery.style';
import { CommonParagraph } from '../common';

const Gallery = ({ images: imagesProps }) => {
  const formattedImages = imagesProps.map((path, index) => ({
    src: path,
    alt: `Screenshot ${index + 1}`,
  }));

  return (
    <>
      <Carousel
        images={formattedImages}
        style={{
          height: 700,
          aspectRatio: 1.76,
        }}
        shouldMaximizeOnClick={true}
        shouldMinimizeOnClick={true}
        hasMediaButton={false}
        hasIndexBoard="bottomCenter"
        leftIcon={<LeftArrow />}
        rightIcon={<RightArrow />}
        maxIcon={<Maximize />}
        minIcon={<Minimize />}
      />
      <CommonParagraph>
        The above image viewer is <code>react-gallery-carousel</code> by{' '}
        <AttributionLink
          href="https://github.com/yifaneye"
          target="_blank"
          rel="noopener noreferrer"
        >
          yifaneye
        </AttributionLink>
        , licensed under MIT.{' '}
        <AttributionLink
          external={true}
          href="https://github.com/yifaneye/react-gallery-carousel?tab=MIT-1-ov-file"
          target="_blank"
          rel="noopener noreferrer"
        >
          Source & license info here
        </AttributionLink>
        .
      </CommonParagraph>
    </>
  );
};

export default Gallery;
