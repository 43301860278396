import { Link } from 'react-router-dom';
import styled from 'styled-components';

const largeBp = props => props.theme.bp.lg;
const midBp = props => props.theme.bp.md;
const xtraSmallBp = props => props.theme.bp.xs;

const padXl = props => props.theme.pad.xl;
const padLg = props => props.theme.pad.lg;
const padMd = props => props.theme.pad.md;
const padSm = props => props.theme.pad.sm;

const whiteFg = props => props.theme.color.fg.white;
const blackFg = props => props.theme.color.fg.black;
const whiteBg = props => props.theme.color.bg.white;
const blackBg = props => props.theme.color.bg.black;

export const Container = styled.header`
  display: 100%;

  background-color: ${({ darkTheme }) => (darkTheme ? blackBg : whiteBg)};
  color: ${({ darkTheme }) => (darkTheme ? whiteFg : blackFg)};

  transition: ${p => p.theme.transition};

  border-bottom: 1px solid ${({ darkTheme }) => (darkTheme ? whiteFg : blackFg)};
`;

export const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  max-width: ${props => props.theme.bp['2xl']};
  width: 100%;
  margin: 0 auto;
  padding: 24px ${padXl};

  /* 1024px */
  @media screen and (max-width: ${largeBp}) {
    padding: 24px ${padLg};
  }

  /* 768px */
  @media screen and (max-width: ${midBp}) {
    padding: 24px ${padMd};
    flex-direction: column;
    gap: 20px;
  }

  /* 480px */
  @media screen and (max-width: ${xtraSmallBp}) {
    padding: 24px ${padSm};
  }
`;

const BaseGroup = styled.div`
  gap: 20px;
  align-items: center;
`;

const DefaultGroup = styled(BaseGroup)`
  display: flex;
`;

const MobileGroup = styled(BaseGroup)`
  display: none;

  @media screen and (max-width: ${midBp}) {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;

    width: 100%;
  }
`;

const DesktopGroup = styled(BaseGroup)`
  display: flex;

  @media screen and (max-width: ${midBp}) {
    display: none;
  }
`;

export const Group = ({ mobile, desktop, children, left }) => {
  if (mobile) {
    return <MobileGroup>{children}</MobileGroup>;
  } else if (desktop) {
    return <DesktopGroup>{children}</DesktopGroup>;
  } else {
    return <DefaultGroup>{children}</DefaultGroup>;
  }
};

export const LogoText = styled(Link)`
  margin-top: 6px;
  text-decoration: none;
  color: inherit;

  font-size: 1.5rem;
  font-family: 'Syncopate', sans-serif;
  font-weight: 700;
`;
