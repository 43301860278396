import { useState } from 'react';

import { ArrowWrap, Text, Wrap } from './TextAndArrow.style';
import { FiArrowRight, FiArrowUpRight } from 'react-icons/fi';

const TextAndArrow = ({ children, trigger, small, externalUrl }) => {
  const [internalTrigger, setInternalTrigger] = useState(false);

  if (externalUrl) {
    return (
      <a
        href={externalUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <Wrap
          onMouseEnter={() => setInternalTrigger(true)}
          onMouseLeave={() => setInternalTrigger(false)}
          small={small}
        >
          <Text small={small}>{children}</Text>
          <ArrowWrap trigger={internalTrigger} small={small}>
            <FiArrowUpRight />
          </ArrowWrap>
        </Wrap>
      </a>
    );
  }

  return (
    <Wrap small={small}>
      <Text small={small}>{children}</Text>
      <ArrowWrap trigger={trigger} small={small}>
        <FiArrowRight />
      </ArrowWrap>
    </Wrap>
  );
};

export default TextAndArrow;
