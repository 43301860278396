import { Link } from 'react-router-dom';
import styled from 'styled-components';

const primary = props => props.theme.color.primary;
const secondary = props => props.theme.color.secondary;
const whiteFg = props => props.theme.color.fg.white;
const blackFg = props => props.theme.color.fg.black;

const midBp = props => props.theme.bp.md;
const smallBp = props => props.theme.bp.sm;
const xsBp = props => props.theme.bp.xs;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 56px;

  @media screen and (max-width: ${smallBp}) {
    flex: 1;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;

  @media screen and (max-width: ${midBp}) {
  }
`;

export const ColorSpan = styled.span`
  background-color: ${({ darkTheme }) => (darkTheme ? secondary : primary)};
  color: ${({ darkTheme }) => (darkTheme ? whiteFg : blackFg)};

  padding: 8px 24px 8px 16px;
  border-radius: 70px;

  transition: ${p => p.theme.transition};

  @media screen and (max-width: ${xsBp}) {
    padding: 6px 12px 4px 0px;
    border-radius: 0 15px 15px 0;
  }
`;

export const P = styled.p`
  font-size: 1.25rem;

  @media screen and (max-width: ${midBp}) {
    font-size: 1.125rem;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 1rem;
  }
`;

export const CTA = styled(Link)`
  background-color: transparent;
  color: ${primary};

  color: ${({ darktheme }) => (darktheme === 'true' ? primary : secondary)};

  border: 2px solid
    ${({ darktheme }) => (darktheme === 'true' ? primary : secondary)};
  padding: 18px 36px;

  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 600;

  transition: 200ms ease-in-out;

  &:hover {
    background-color: ${({ darktheme }) =>
      darktheme === 'true' ? primary : secondary};
    color: ${({ darktheme }) => (darktheme === 'true' ? blackFg : whiteFg)};
  }

  @media screen and (max-width: ${midBp}) {
    width: 100%;

    text-align: center;
  }

  @media screen and (max-width: ${smallBp}) {
    padding: 12px 24px;
  }
`;
