import styled from 'styled-components';
import waveSvg from '../../assets/wave.svg';
import waveSvgSm from '../../assets/waveSm.svg';

const midBp = props => props.theme.bp.md;
const smallBp = props => props.theme.bp.sm;
const xtraSmallBp = props => props.theme.bp.xs;

const primary = props => props.theme.color.primary;
const blackBg = props => props.theme.color.bg.black;
const whiteBg = props => props.theme.color.bg.white;

export const FooterWrap = styled.footer`
  position: relative;
`;

export const Flag = styled.img`
  width: 86px;
  height: auto;

  @media screen and (max-width: ${smallBp}) {
    width: 60px;
  }
`;

export const Group = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const Heading = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;

  @media screen and (max-width: ${midBp}) {
    font-size: 1.25rem;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 1.125rem;
  }
`;

export const Contact = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-left: none;
  border-right: none;

  @media screen and (max-width: ${midBp}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 24px 0;
  }
`;

export const Email = styled.p`
  font-size: 2rem;
  font-weight: 600;

  @media screen and (max-width: ${midBp}) {
    font-size: 1.75rem;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: ${xtraSmallBp}) {
    word-break: break-all;
  }
`;

export const EmailActions = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  @media screen and (max-width: ${midBp}) {
    width: 100%;
  }

  @media screen and (max-width: ${smallBp}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Icon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  font-size: 1.5rem;
  border-radius: 50%;
  transition: all 0.2s ease;
  border: 2px solid ${blackBg};
  color: ${blackBg};

  background-color: transparent;

  &:hover {
    background-color: ${blackBg};
    color: ${primary};
  }
`;

export const FooterP = styled.p``;

export const IconsGroup = styled.div`
  display: flex;
`;

export const AnimatedBgWrap = styled.div`
  height: 176px;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  overflow: hidden;

  background: ${({ darkTheme }) => (darkTheme ? blackBg : whiteBg)};
  transition: ${props => props.theme.transition};

  @keyframes wave {
    0% {
      margin-left: 0;
    }
    100% {
      margin-left: -1600px;
    }
  }

  @keyframes swell {
    0%,
    100% {
      transform: translate3d(0, -20px, 0);
    }
    50% {
      transform: translate3d(0, 5px, 0);
    }
  }
`;

export const AnimBg = styled.div`
  background: url(${waveSvg}) repeat-x;
  position: absolute;
  bottom: 0;
  width: 6400px;
  height: 150px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);

  :nth-of-type(2) {
    bottom: 2px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
      swell 7s ease -1.25s infinite;
    opacity: 0.7;
  }

  @media screen and (max-width: ${midBp}) {
    background: url(${waveSvgSm}) repeat-x;
  }
`;
