export const theme = {
  color: {
    primary: '#ECB30B',
    secondary: '#912F40',
    fg: {
      black: '#000',
      white: '#fff',
    },
    bg: {
      black: '#171717',
      white: '#faf9f6',
    },
  },
  bp: {
    xs: '480px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
  pad: { sm: '1.5rem', md: '2rem', lg: '2.5rem', xl: '4rem' },

  // ? for the slow blend effect when switching theme
  transition: 'all 500ms ease-in-out',
};
