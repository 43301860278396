export const getCurrentAge = () => {
  const today = new Date();
  const birthDate = new Date('2000-02-19');
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;

  return age;
};
