import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeContext } from './context/ThemeContext';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './routes/root';
import { About, Home, ProjectDetails, WorkDetails } from './pages';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <div>404 Page not found!</div>,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'portfolio/project/:id',
        element: <ProjectDetails />,
      },
      {
        path: 'portfolio/work/:id',
        element: <WorkDetails />,
      },
      {
        path: 'about',
        element: <About />,
      },
    ],
  },
]);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeContext>
      <RouterProvider router={router} />
    </ThemeContext>
  </React.StrictMode>
);
