import {
  CommonHeading,
  CommonSubheading,
  Container,
  Projects,
  Tag,
  TagGroup,
  TextAndArrow,
} from '../../components';
import { useThemeContext } from '../../context/ThemeContext';
import { useParams } from 'react-router-dom';
import { projectsList } from '../../data';
import {
  Banner,
  Image,
  Group,
  ImageWrap,
  Paragraph,
  Section,
  Subtitle,
  Title,
  ToolingAndFunctionalities,
  Wrap,
  DoubleImageWrap,
} from './ProjectDetails.style';

const ProjectDetails = () => {
  const { darkTheme } = useThemeContext();
  const { id } = useParams();

  const project = projectsList.find(
    project => project.name.toLowerCase() === id
  );

  if (!project) {
    return <h1>Project not found</h1>;
  }

  const {
    type,
    name,
    summary,
    tags,
    description,
    tooling,
    functionalities,
    urls,
    images,
    content,
  } = project;

  return (
    <>
      <Container darkTheme={darkTheme}>
        <Wrap>
          {/* SECTION 1: TITLE & INTRO */}
          <Section column>
            <CommonHeading>{name}</CommonHeading>
            <Group
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
              }}
            >
              <Paragraph>{summary}</Paragraph>
              <TagGroup>
                {type === 'project' && (
                  <Tag style={{ backgroundColor: 'cyan' }}>Personal</Tag>
                )}
                {tags.map((tag, index) => (
                  <Tag key={index} darkTheme={darkTheme}>
                    {tag}
                  </Tag>
                ))}
              </TagGroup>
            </Group>
            <Group>
              <CommonSubheading style={{ marginBottom: 12 }}>
                Overview
              </CommonSubheading>
              <Group
                customStyles={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',

                  '@media screen and (max -width: 768px)': {
                    flexDirection: 'row',
                    gap: '72px',
                  },
                }}
              >
                {description.map((paragraph, index) => (
                  <Paragraph key={index}>{paragraph}</Paragraph>
                ))}
              </Group>
            </Group>
            <ToolingAndFunctionalities>
              <TitleAndList title="This was built using;" list={tooling} />
              <TitleAndList title="And it basically;" list={functionalities} />
            </ToolingAndFunctionalities>
            <Banner
              src={images.banner[0]}
              alt="image of project user interface"
            />
            <Group
              customStyles={{
                display: 'flex',
                flexDirection: 'column',
                gap: '18px',
              }}
            >
              <Subtitle>Visit the</Subtitle>
              <Group
                customStyles={{
                  display: 'flex',
                  gap: '32px',
                  flexWrap: 'wrap',

                  '@media screen and (max-width: 480px)': {
                    gap: '16px',
                  },
                }}
              >
                <TextAndArrow small externalUrl={urls.live}>
                  live website
                </TextAndArrow>
                {urls.github && (
                  <TextAndArrow small externalUrl={urls.github}>
                    github page
                  </TextAndArrow>
                )}
              </Group>
            </Group>
          </Section>

          {/* SECTION 2: PURPOSE & GOAL */}
          <Section>
            <Group
              customStyles={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
              }}
            >
              <Title>Purpose and goal</Title>
              <Group
                customStyles={{
                  flex: '1',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                {content.purpose.map((paragraph, index) => (
                  <Paragraph key={index}>{paragraph}</Paragraph>
                ))}
              </Group>
            </Group>
            <ImageWrap>
              <Image src={images.concept} alt="image of concept design" />
            </ImageWrap>
          </Section>

          {/* SECTION 3: INTERESTING BITS */}
          <Section flip>
            <Group
              customStyles={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
              }}
            >
              <Title>Interesting bits</Title>
              <Group
                customStyles={{
                  flex: '1',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                {content.spotlight.map((paragraph, index) => (
                  <Paragraph key={index}>{paragraph}</Paragraph>
                ))}
              </Group>
            </Group>
            <ImageWrap>
              <Image src={images.spotlight} alt="image of concept design" />
            </ImageWrap>
          </Section>

          {/* SECTION 4: GALLERY */}
          <Section>
            <ImageWrap>
              <Image
                src={images.gallery[0]}
                alt="photos of project user interface"
              />
            </ImageWrap>

            <DoubleImageWrap>
              <Image
                src={images.gallery[1]}
                alt="photos of project user interface"
              />
              <Image
                src={images.gallery[2]}
                alt="photos of project user interface"
              />
            </DoubleImageWrap>
          </Section>

          {/* SECTION 5: CHALLENGES & COGNITIVE APPROACH */}
          <Section column middle>
            <Group
              customStyles={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
              }}
            >
              <Title>Challenges and cognitive approach</Title>
              <Group
                customStyles={{
                  flex: '1',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                {content.challenges.map((paragraph, index) => (
                  <Paragraph key={index}>{paragraph}</Paragraph>
                ))}
              </Group>
            </Group>
            <Banner src={images.banner[1]} alt="image of concept design" />
          </Section>

          {/* SECTION 6: CURRENT STATUS & LESSONS LEARNED */}
          <Section column middle>
            <Group
              customStyles={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
              }}
            >
              <Title>At the moment</Title>
              <Group
                customStyles={{
                  flex: '1',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                {content.status.map((paragraph, index) => (
                  <Paragraph key={index}>{paragraph}</Paragraph>
                ))}
              </Group>
            </Group>
            <Group
              customStyles={{
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
              }}
            >
              <Title>Key takeaways</Title>
              <Group
                customStyles={{
                  flex: '1',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                {content.takeaways.map((paragraph, index) => (
                  <Paragraph key={index}>{paragraph}</Paragraph>
                ))}
              </Group>
            </Group>
          </Section>
        </Wrap>
      </Container>
      <Projects />
    </>
  );
};

const TitleAndList = ({ title, list }) => (
  <Group
    customStyles={{
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    }}
  >
    <Subtitle>{title}</Subtitle>
    <ul>
      {list.map((item, index) => (
        <li
          key={index}
          style={{
            listStylePosition: 'inside',
          }}
        >
          {item}
        </li>
      ))}
    </ul>
  </Group>
);

export default ProjectDetails;
