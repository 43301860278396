import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const whiteFg = props => props.theme.color.bg.white;
const blackFg = props => props.theme.color.bg.black;
const whiteBg = props => props.theme.color.bg.white;
const blackBg = props => props.theme.color.bg.black;

const padXl = props => props.theme.pad.xl;
const padLg = props => props.theme.pad.lg;
const padMd = props => props.theme.pad.md;
const padSm = props => props.theme.pad.sm;

const largeBp = props => props.theme.bp.lg;
const midBp = props => props.theme.bp.md;
const smallBp = props => props.theme.bp.sm;
const xtraSmallBp = props => props.theme.bp.xs;

export const Container = styled.section`
  position: ${props => props.position || ''};

  background: ${({ bg, darkTheme }) =>
    bg ? bg : darkTheme ? blackBg : whiteBg};
  color: ${({ darkTheme }) => (darkTheme ? whiteFg : blackFg)};

  padding: ${props => props.padding || '32px 0 64px 0'};

  transition: ${props => props.theme.transition};

  @media screen and (max-width: ${midBp}) {
    margin-right: 0;
  }
`;

export const Wrap = styled.div`
  max-width: ${props => props.theme.bp['2xl']};
  width: 100%;
  margin: 0 auto;
  padding: 24px ${padXl};

  /* 1024px */
  @media screen and (max-width: ${largeBp}) {
    padding: 24px ${padLg};
  }

  /* 768px */
  @media screen and (max-width: ${midBp}) {
    flex-direction: ${({ reverseCol }) =>
      reverseCol ? 'column-reverse' : 'column'};

    padding: 24px ${padMd};
  }

  /* 480px */
  @media screen and (max-width: ${xtraSmallBp}) {
    padding: 24px ${padSm};
  }
`;

export const CommonHeading = styled.h1`
  color: inherit;
  font-size: 2.25rem;

  @media screen and (max-width: ${midBp}) {
    font-size: 1.875rem;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 1.5rem;
  }
`;

export const CommonSubheading = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;

  @media screen and (max-width: ${midBp}) {
    font-size: 1.375rem;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 1.25rem;
  }
`;

export const CommonParagraph = styled.p``;

const LinkStyles = css`
  text-decoration: none;
  color: inherit;

  font-size: 1.25rem;
  transition: 200ms ease-in-out;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid;
  }
`;

const ExternalLink = styled.a`
  ${LinkStyles}
`;

const RouterLink = styled(Link)`
  ${LinkStyles}
`;

export const NavLink = ({ external, children, href }) => {
  if (external)
    return (
      <ExternalLink href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </ExternalLink>
    );
  else return <RouterLink to={href}>{children}</RouterLink>;
};

export const TagGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
`;

export const Tag = styled.span`
  display: inline-block;

  background: ${({ darkTheme }) => (darkTheme ? '#333' : '#ccc')};
  color: ${({ darkTheme }) => (darkTheme ? '#fff' : '#000')};

  padding: 4px 8px;
  border-radius: 100px;
`;
