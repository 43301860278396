import styled from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;
`;

export const RotationAnimation = styled.div`
  animation: spin 20s infinite linear;

  &:hover {
    animation-play-state: paused;
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const WiggleAnimation = styled.div`
  animation: rock 4.5s infinite ease-in-out;

  &:hover {
    animation-play-state: paused;
  }

  @keyframes rock {
    0% {
      transform: rotate();
    }
    50% {
      transform: rotate(-15deg);
    }
    100% {
      transform: rotate(0);
    }
  }
`;
