import styled, { css } from 'styled-components';
import { CommonParagraph, Wrap as CommonWrap } from '../../components';

const largeBp = props => props.theme.bp.lg;
const midBp = props => props.theme.bp.md;
const smallBp = props => props.theme.bp.sm;

export const Wrap = styled(CommonWrap)`
  display: flex;
  flex-direction: column;
  gap: 13rem;

  margin-bottom: 6.25rem;

  @media (max-width: ${midBp}) {
    gap: 8rem;
    margin-bottom: 4rem;
  }

  @media (max-width: ${smallBp}) {
    gap: 5rem;
    margin-bottom: 2rem;
  }

  /* @media (max-width: ${smallBp}) {
    gap: 1.5rem;
  } */
`;

export const Section = styled.div`
  display: flex;
  ${({ column, flip, middle }) =>
    column
      ? css`
          flex-direction: column;
          align-items: ${middle ? 'center' : 'flex-start'};
          ${({ middle }) => middle && 'text-align: center;'}
        `
      : css`
          flex-direction: ${flip ? 'row-reverse' : 'row'};
          align-items: center;
        `}

  gap: 3.5rem;

  @media (max-width: ${largeBp}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;

    text-align: left;
  }
`;

export const Paragraph = styled(CommonParagraph)`
  max-width: 800px;
`;

export const Group = styled.div`
  ${({ customStyles }) => customStyles}
`;

export const ToolingAndFunctionalities = styled.div`
  display: flex;
  justify-content: space-between;

  max-width: 800px;
  width: 100%;

  @media (max-width: ${midBp}) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const Title = styled.p`
  font-size: 1.75rem;
  font-weight: 600;
`;

export const Subtitle = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const ImageWrap = styled.div`
  flex: 1;
  width: 100%;
`;

export const DoubleImageWrap = styled(ImageWrap)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const DefaultImageStyles = css`
  width: 100%;
  height: auto;
  border: 1px solid #888;

  object-fit: cover;
`;

export const Banner = styled.img`
  ${DefaultImageStyles}

  @media (max-width: ${midBp}) {
    height: auto;
  }
`;

export const Image = styled.img`
  ${DefaultImageStyles}

  @media (max-width: ${largeBp}) {
    height: auto;
  }
`;
