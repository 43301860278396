import styled from 'styled-components';

const smallBp = props => props.theme.bp.sm;

const primary = props => props.theme.color.primary;
const secondary = props => props.theme.color.secondary;

export const Container = styled.div`
  position: relative;

  width: 100%;
  min-width: 300px;

  @media screen and (max-width: ${smallBp}) {
    min-width: 180px;
  }
`;

export const Wrap = styled.div`
  border-radius: 999px 999px 0 0;
  background-color: ${({ darkTheme }) => (darkTheme ? primary : secondary)};

  transition: ${p => p.theme.transition};

  &:hover {
    background-color: transparent;
  }
`;

export const NoBgWrap = styled(Wrap)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Photo = styled.img`
  width: 100%;
  height: auto;
  border-radius: 999px 999px 0 0;
`;

export const PhotoNoBg = styled(Photo)`
  filter: grayscale(1);
  transition: ${p => p.theme.transition};

  &:hover {
    filter: grayscale(0);
  }
`;
