export const about = {
  resumeURL:
    'https://drive.google.com/file/d/1gec1IAM7hNbYVwZVR8cIm6aaaoSarya1/view?usp=sharing',
  paragraphs: [
    // {
    //   sectionTitle: 'Hello',
    //   content: [
    //     `I'm Bunmi Oye, a budding web developer from Nigeria. I like to build cool stuff on and off the web.`,
    //   ],
    // },
    {
      sectionTitle: 'Journey',
      content: [
        `During the pandemic, like many, I dipped my toes into coding, 
      juggling it with the final stretch of my academics. 
      It's been a rollercoaster of frustration and excitement, 
      and I wouldn't trade it – well, most of it – for anything.`,
        `My earliest experience with programming goes back to high school when my brother 
      and I discovered BATCH. We were determined to create an interactive text-based 
      adventure game on our home computer using basic conditionals. Sadly, we never 
      got to complete the game because the computer ran into issues, but that early 
      spark ignited my passion for technology, setting me on the path to where I am today.`,
        `After securing my degree in Civil Engineering, I charted a new course into the dynamic 
      world of tech. Coding wasn't just a post-graduate revelation; I was tinkering with it 
      even as an undergrad. I've also had a taste of the industry through internships and freelancing.`,
      ],
    },
    {
      sectionTitle: 'Now',
      content: [
        `Today, I'm on an exciting journey to explore web development further. 
      It's not just a career path; it's where my heart truly lies. Crafting interactive 
      websites and applications is my passion, and I'm committed to creating digital experiences 
      that are not only user-friendly but ahead of the curve.`,
        `Outside of code, you’ll find me reading, engaging in lively conversations, enjoying movies 
      and anime with friends, DIY-ing, and occasionally motivating myself to go to the gym.`,
      ],
    },
  ],
  // paragraphs: {
  //   left: [
  //     "Hey there! I'm Bunmi Oye, a front-end developer specializing in converting designs into functional websites.",
  //     'React JS is my go-to tool for building systems piece by piece.',
  //     'Start-ups are my jam—I thrive on finding solutions to tricky user and organizational issues.',
  //     "When I'm not coding, you'll find me indulging in reading, movies, anime, and music. Let's create something awesome together!",
  //   ],
  //   right: [
  //     // "Hey there! I'm Bunmi Oye, a front-end developer experienced in converting designs into fully functional websites.",
  //     'With a passion for building systems piece by piece, I have fallen in love with the component-based style of React JS. and use it as my primary front-end tool.',
  //     'I have experience working with start-up businesses and enjoy figuring out solutions to challenging user and organizational issues.',
  //     "When I'm not coding, you can find me reading, watching movies and anime, or listening to music.",
  //   ],
  // },
};
