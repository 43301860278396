import { FiLinkedin, FiTwitter, FiGithub } from 'react-icons/fi';

export const socialMediaList = [
  // {
  //   name: 'email',
  //   url: 'mailto:bunmioye09@gmail.com',
  //   icon: <FaEnvelope />
  // },
  {
    name: 'linked in',
    url: 'https://www.linkedin.com/in/bunmi-oye',
    icon: <FiLinkedin />,
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/cyanhead',
    icon: <FiTwitter />,
  },
  {
    name: 'github',
    url: 'https://www.github.com/cyanhead',
    icon: <FiGithub />,
  },
  // {
  //   id: 4,
  //   name: 'telegram',
  //   url: 'https://t.me/cyanhead',
  //   icon: <FaTelegramPlane />
  // }
];
