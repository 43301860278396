import styled from 'styled-components';
import { Wrap as CommonWrap, CommonParagraph } from '../common';

const midBp = props => props.theme.bp.md;
const smallBp = props => props.theme.bp.sm;

const primary = props => props.theme.color.primary;
const secondary = props => props.theme.color.secondary;
const whiteFg = props => props.theme.color.fg.white;

export const Wrap = styled(CommonWrap)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 56px;

  @media screen and (max-width: ${midBp}) {
    gap: 32px;
  }

  @media screen and (max-width: ${smallBp}) {
    gap: 16px;
  }
`;

export const Paragraph = styled(CommonParagraph)`
  max-width: 600px;

  @media screen and (max-width: ${midBp}) {
    max-width: 100%;
  }
`;

export const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 64px;
  row-gap: 128px;

  @media screen and (max-width: ${midBp}) {
    grid-template-columns: repeat(2, 1fr);

    column-gap: 32px;
    row-gap: 64px;
  }

  @media screen and (max-width: 620px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 64px;
  }
`;

export const ProjectItem = styled.div`
  opacity: ${({ disabled }) => (disabled ? '0.3' : '')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;

  color: inherit;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${midBp}) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: ${({ col }) => (col ? 'column' : 'row-reverse')};
  align-items: ${({ col }) => (col ? 'stretch' : 'center')};
  justify-content: ${({ col }) => (col ? 'center' : 'space-between')};

  padding: ${({ border }) => (border ? '0 0 20px 0' : 0)};

  @media screen and (max-width: ${midBp}) {
    flex-direction: ${({ col }) => (col ? 'column' : 'row')};
  }
`;

export const GroupLink = ({ children, ...props }) => {
  return (
    <a
      href={props.href}
      target="_blank"
      rel="noreferrer"
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <Group {...props}>{children}</Group>
    </a>
  );
};

export const HiddenGroup = styled(Group)`
  opacity: ${({ show }) => (show ? 1 : 0)};

  padding: 0.75rem;

  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  @media screen and (max-width: ${midBp}) {
    opacity: 1;
    position: static;

    padding: 1rem 0;
  }
`;

export const Name = styled.h1`
  font-family: 'Montserrat Variable', sans-serif;
  font-size: 2rem;

  @media screen and (max-width: ${midBp}) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 1.25rem;
  }
`;

export const Category = styled.p`
  font-size: 1.25rem;

  @media screen and (max-width: ${midBp}) {
    font-size: 1.125rem;
  }

  @media screen and (max-width: ${smallBp}) {
    font-size: 1rem;
  }
`;

export const ImageWrap = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 20px;

  &:before {
    content: '';
    z-index: 10;
    width: 100%;
    height: 100%;
    display: block;
    position: ${({ show }) => (show ? 'absolute' : 'static')};
    background: linear-gradient(
      to bottom,
      transparent 50%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.8)
    );

    @media screen and (max-width: ${midBp}) {
      position: static;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  margin-top: 20px;

  @media screen and (max-width: ${midBp}) {
    margin-top: 0px;
  }
`;

export const Tooling = styled.div`
  margin-left: 1rem;

  color: white;

  transition: ${props => props.theme.transition};

  @media screen and (max-width: ${midBp}) {
    margin-left: 0;
    margin-right: 1rem;
    color: ${({ darkTheme }) => (darkTheme ? 'white' : 'black')};
  }
  @media screen and (max-width: ${smallBp}) {
    font-size: 0.875rem;
  }
`;

export const GithubRepoLinkWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 8px 0;
  padding: 16px;
`;

export const GithubRepoLink = styled.a`
  color: ${({ darkTheme }) => (darkTheme ? primary : secondary)};

  padding: 16px 40px;

  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 600;

  transition: ${props => props.theme.transition};

  :hover {
    color: ${({ darkTheme }) => (darkTheme ? whiteFg : 'black')};

    text-decoration: underline;
  }
`;
