import styled from 'styled-components';

export const P = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 1rem;
  border-bottom: 1px solid transparent;
`;

export const Button = styled.button`
  font-size: 1rem;
  border: none;
  background: none;
  cursor: pointer;

  transition: 200ms ease-in-out;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid ${p => p.theme.color.fg.black};
  }
`;
