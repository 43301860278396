import styled from 'styled-components';

const largeBp = props => props.theme.bp.lg;

export const Grid = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 32px;
  row-gap: 64px;

  @media screen and (max-width: ${largeBp}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
