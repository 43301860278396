import React, { useState } from 'react';

import { Group, Heading, Link, Thumbnail, Wrap } from './ProjectCard.style';
import { CommonParagraph, Tag, TagGroup } from '../common';
import { useThemeContext } from '../../context/ThemeContext';
import TextAndArrow from '../TextAndArrow';

const ProjectCard = ({ type, thumbnail, pathId, title, summary, tags }) => {
  const [hover, setHover] = useState(false);
  const { darkTheme } = useThemeContext();

  const projectPath = `/portfolio/project/${pathId}`;
  const workPath = `/portfolio/work/${pathId}`;

  return (
    <Link
      to={type === 'work' ? workPath : projectPath}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      darktheme={darkTheme ? 'true' : 'false'}
    >
      <Wrap>
        <Thumbnail
          src={thumbnail}
          alt={`thumbnail of ${title} project`}
          hover={hover}
        />
        <Group>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Heading>{title}</Heading>
            <TagGroup>
              {type === 'project' && (
                <Tag style={{ backgroundColor: 'cyan' }}>Personal</Tag>
              )}
              {tags.map((tag, index) => (
                <Tag key={index} darkTheme={darkTheme}>
                  {tag}
                </Tag>
              ))}
            </TagGroup>
          </div>
          <CommonParagraph>{summary}</CommonParagraph>
          <TextAndArrow trigger={hover}>View project</TextAndArrow>
        </Group>
      </Wrap>
    </Link>
  );
};

export default ProjectCard;
