import * as Img from './images';

export const workList = [
  {
    type: 'work',
    id: 'mytechietrader',
    name: 'MyTechieTrader',
    tags: [
      'IT',
      'Hardware',
      'Shopping',
      'Consulting',
      //
    ],
    summary: 'Streamlining IT component sourcing for businesses.',
    thumbnail: Img.techietrader_thumbnail,
    description: [
      'MyTechieTrader is a platform where small teams and businesses can easily get access to IT components, handling everything from inquiry to delivery.',
      'I was tasked with building a pixel-perfect implementation of the website according the designs.',
    ],
    tooling: ['React', 'TypeScript', 'Next JS', 'Tailwind CSS', 'Google APIs'],
    objectives: [
      'create a web presence for the already running service',
      'shift from offline to seamless online shopping',
    ],
    urls: {
      live: 'https://mytechietrader.com',
    },
    images: {
      banner: Img.techietrader_hero,
      concept: Img.techietrader_designs,
      spotlight: Img.techietrader_form,
      gallery: [
        Img.techietrader_hero,
        Img.techietrader_hero2,
        Img.techietrader_catalogue,
        Img.techietrader_form,
        Img.techietrader_faqs,
        Img.techietrader_designs,
        Img.techietrader_hero_mobile,
        Img.techietrader_steps_mobile,
        Img.techietrader_form_mobile,
      ],
    },
    content: {
      role: [
        'This project originally aimed to become a shopping platform. However, during my involvement, the team required a website to showcase their services and transition existing customers to online shopping while attracting new ones.',
        // 'I solely handled the development of the website, ensuring I adhered to accessibility standards and that it was fully responsive regardless of the device it is viewed on.',
      ],
      challenges: [
        'My main challenge revolved around implementing the Product Discovery Form. Firstly, to minimize reliance on external packages, I developed the file upload component in-house',
        "Secondly, given the project's initial stage and the team's desire to limit expenses, I proposed and established a solution to store entered data in a Google Sheets file, and images/files in a designated Google Drive folder.",
      ],
      outcome: [
        'The website provided the business with a crucial online presence, expanding its reach to new customers through effective marketing strategies.',
      ],
    },
  },
  {
    type: 'work',
    id: 'clik',
    name: 'Clik: Landing Page',
    tags: ['Social', 'Communication', 'Online Community'],
    summary:
      'Connect with the people you care about in a private and personal way.',
    thumbnail: Img.clik_thumbnail,
    description: [
      'With Clik, you can create personalized "Cliques" of trusted individuals, inviting only those you choose. Share your pictures and videos selectively, deciding which Cliques to share them with. Enjoy social media without distractions or interference from outsiders.',
      'I was tasked with building a pixel-perfect implementation of the website according the designs.',
    ],
    tooling: ['React', 'Firebase', 'Styled-Components', 'MailChimp'],
    objectives: [
      'create a web presence for the product to attract users',
      "guide visitors through the app's functionality and features",
      'add interested visitors to a wait (mailing) list',
    ],
    urls: {
      live: 'https://useclik.com/',
    },
    images: {
      banner: Img.clik_banner,
      concept: Img.clik_designs,
      spotlight: Img.clik_spotlight,
      gallery: [
        Img.clik_banner,
        Img.clik_gallery1,
        Img.clik_gallery2,
        Img.clik_gallery3,
        Img.clik_gallery4,
        Img.clik_designs,
        Img.clik_mobile1,
        Img.clik_mobile2,
      ],
    },
    content: {
      role: [
        "The product needed a landing page to guide the users through the app's various features, answering frequently asked questions as well as adding interested visitors to a mailing list pending the app's public launch.",
        'I solely handled the development of the website, ensuring I adhered to accessibility standards and that it was fully responsive regardless of the device it is viewed on.',
      ],
      challenges: [
        'While this was a relatively easy project, I did have  some trouble with implementing the mailing list (newsletter) feature as I was new to the tech and had try out a few alternatives before settling for MailChimp. I was able to set it up successfully after reading the documentation.',
        'There were minor challenges with the layout as well, due to the unique design. For example, the text here "Clique" is not an image and so I had to implement the shadow outline below. I was able to navigate these without much struggle.',
      ],
      outcome: [
        'I built a pixel-perfect website according to the provided designs, and hosted it on Google Firebase.',
        "The website delivered on it's intended purpose and there was a significant increase in the number of interested users joining the wait list.",
      ],
    },
  },
  {
    type: 'work',
    id: 'terrace',
    name: 'Terrace',
    tags: ['Finance', 'Crypto', 'Blockchain'],
    summary: 'A digital asset trading platform.',
    thumbnail: Img.terrace_thumbnail,
    description: [
      'Terrace is a Y-Combinator-backed startup that allows teams to acquire and manage digital assets on the blockchain through their intuitive web app.',
    ],
    tooling: ['React', 'Next JS', 'TypeScript'],
    objectives: ['help teams better manage their digital assets'],
    // urls: {live: '',github:''},
    images: {
      banner: Img.terrace_home,
      concept: Img.terrace_sharePNLRocket,
      spotlight: null,
      gallery: [
        Img.terrace_badges,
        Img.terrace_blockchainAddressCopy,
        Img.terrace_discord,
        Img.terrace_percentageInput,
        Img.terrace_sharePNL,
        Img.terrace_sharePNLModal,
        Img.terrace_timeUnit,
      ],
    },
    content: {
      role: [
        'I was brought on board to address the pressing needs of the project, which required a substantial amount of work within a tight timeframe.',
      ],
      challenges: [
        // 'During my time on this job, I touched many components and ran into numerous issues, but I did not have any major challenges. Although I hit walls when setting up my environments, but successfully resolved these with the help of my team lead.',
        'Throughout this project, I engaged with various components and encountered several minor issues. Setting up my environments initially presented some hurdles; however, with effective guidance from my team lead, I was able to overcome these challenges successfully.',
      ],
      outcome: [
        'I collaborated across multiple components, translating designs into clean and functional code. Below, highlighted in red, are some of my key contributions.',
      ],
    },
  },
  // {
  //   type: '',
  //   id: '',
  //   name: '',
  //   tags: ['', '', ''],
  //   summary: '',
  //   thumbnail: null,
  //   description: ['', ''],
  //   tooling: ['', '', '', ''],
  //   objectives: ['', '', ''],
  //   urls: {
  //     live: '',
  //   },
  //   images: {
  //     banner: null,
  //     concept: null,
  //     spotlight: null,
  //     gallery: [null, null, null, null, null, null, null, null],
  //   },
  //   content: {
  //     role: ['', ''],
  //     challenges: ['', ''],
  //     outcome: ['', ''],
  //   },
  // },
];
