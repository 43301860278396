import React from 'react';
import { Wrap, Paragraph } from './projects.style';
import { CommonHeading, Container } from '../common';

import { useThemeContext } from '../../context/ThemeContext';
import ProjectGrid from '../ProjectGrid';

const Projects = () => {
  const { darkTheme } = useThemeContext();

  return (
    <Container id="portfolio" darkTheme={darkTheme}>
      <Wrap>
        <CommonHeading>What I've done recently</CommonHeading>
        <Paragraph>
          I like to keep myself engaged working on something at any given time.
          Take a look at some of them.
        </Paragraph>
        <ProjectGrid />
      </Wrap>
    </Container>
  );
};

export default Projects;
