import { GlobalStyle } from './Global.style';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { Footer, NavBar } from './components';
import { Outlet } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import ScrollToAnchor from './components/ScrollToAnchor';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <ScrollToTop />
      <ScrollToAnchor />

      <NavBar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
