import { socialMediaList } from '../../data';
import { Container, NavLink, Wrap } from '../common';
import {
  FooterWrap,
  Group,
  FooterP,
  AnimatedBgWrap,
  AnimBg,
  Flag,
  Contact,
  Email,
  EmailActions,
  Icon,
  Heading,
} from './footer.style';
import flag from '../../assets/nigerian-flag.svg';
import { useThemeContext } from '../../context/ThemeContext';
import { NavLinks } from '../NavBar';
import CopyToClipboard from '../CopyToClipboard';

const Footer = () => {
  const { darkTheme } = useThemeContext();

  return (
    <FooterWrap>
      {/* WAVE ANIMATION */}
      <AnimatedBgWrap darkTheme={darkTheme}>
        <AnimBg></AnimBg>
        <AnimBg></AnimBg>
      </AnimatedBgWrap>

      <Container
        bg={props => props.theme.color.primary}
        padding="0 0 40px 0"
        position="relative"
      >
        <Wrap
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          <Flag src={flag} alt="nigerian flag" title="Nigerian flag" />
          <Heading>Let's collaborate</Heading>
          <FooterP
            style={{
              maxWidth: '600px',
            }}
          >
            Feel free to get in touch with me if you require a developer, have
            any questions, or simply want to connect.
          </FooterP>
          <Contact id="contact">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.75rem',
              }}
            >
              <Email>bunmi.oye@outlook.com</Email>
              <EmailActions>
                <NavLink
                  href="mailto:bunmi.oye@outlook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontSize: '1rem',
                  }}
                >
                  Open email client
                </NavLink>
                <CopyToClipboard />
              </EmailActions>
            </div>
            <SocialLinks />
          </Contact>
          <Group>
            <NavLinks desktop home />
            <NavLinks mobile left home />
            <FooterP>
              Designed and handcrafted by me @ {new Date().getFullYear()}.
            </FooterP>
          </Group>
        </Wrap>
      </Container>
    </FooterWrap>
  );
};

const SocialLinks = () => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '12px',
      }}
    >
      {socialMediaList.map(({ name, icon, url }, i) => (
        <SocialIcon key={i} name={name} imgSrc={icon} url={url} />
      ))}
    </div>
  );
};

const SocialIcon = ({ url, imgSrc }) => {
  return (
    <Icon href={url} target="_blank" rel="noopener noreferrer">
      {imgSrc}
    </Icon>
  );
};

export default Footer;
