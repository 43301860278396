import styled from 'styled-components';
import { CommonSubheading } from '../common';
import { Link as RouterLink } from 'react-router-dom';

const midBp = props => props.theme.bp.md;
const smallBp = props => props.theme.bp.sm;

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: inherit;

  border: 1px solid transparent;

  transition: 200ms ease-in-out;

  &:hover {
    border: 1px solid
      ${({ darktheme }) => (darktheme === 'true' ? '#444' : '#ccc')};
  }

  /* 640px */
  @media screen and (max-width: ${smallBp}) {
    border: 1px solid
      ${({ darktheme }) => (darktheme === 'true' ? '#444' : '#ccc')};
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Thumbnail = styled.img`
  width: 100%;

  height: 400px;
  object-fit: cover;

  background-size: cover;

  /* 640px */
  @media screen and (max-width: ${smallBp}) {
    height: 300px;
  }
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  padding: 32px;
  padding-top: 0;

  /* 768px */
  @media screen and (max-width: ${midBp}) {
    padding: 24px;
  }

  /* 640px */
  @media screen and (max-width: ${smallBp}) {
    padding: 16px;
  }
`;
export const Heading = styled(CommonSubheading)`
  font-size: 1.75rem;

  /* 768px */
  @media screen and (max-width: ${midBp}) {
    font-size: 1.5rem;
  }

  /* 640px */
  @media screen and (max-width: ${smallBp}) {
    font-size: 1.25rem;
  }
`;
